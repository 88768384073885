import mergewith from 'lodash.mergewith'
import { COLOR_NAME, GAME_EVENT, GAME_ACTION, UNITS } from 'conkis-core'
import { createThree, BOARDS, ENTITIES } from '~/npm/conkis-three'
import { wait } from 'conkis-core/src/utils'
import createQueueAnimations from 'conkis-core/src/utils/createQueueAnimations'
import {
    isWindowActive,
    isWeb,
    isDesktop,
    isLandscape,
    getPixelRatio,
} from '~/utils/device'
import { THREE_ASSETS } from '~/const/assets'
import { TILE_COLORS } from '~/const'
import { AUDIO } from '~/audio'

export default function ({ emitter, board, state }) {
    let three
    let board_three
    let hand
    let camera_position
    let camera_distance
    const tiles_over = {}
    const tiles_range = {}
    const tiles_walk = {}
    const tiles_highlight = {}
    const path_walk = {}
    const path_attack = {}
    const flags = {}
    const units = {}
    const recruiting = {}
    const queues = createQueueAnimations({
        emitter,
        keys: [
            'unit_id',
            'unit_id_target',
            'tile_id',
            'tile_id_to',
            'tile_id_target',
        ],
        onEndQueue: (queue) => {
            // console.log(queue)
        },
    })

    emitter.on(GAME_EVENT.CANVAS_READY, ({ gl, canvas }) => {
        three = createThree({
            gl,
            canvas,
            isWeb,
            isDesktop,
            isLandscape,
            getPixelRatio,
            onWhell: (params) => {
                emitter.emit(GAME_EVENT.CAMERA_POSITION_CANCEL, params)
            },
            onMouseMove: (params) => {
                emitter.emit(GAME_EVENT.MOUSE_MOVE, params)
            },
            onTouchStart: (params) => {
                emitter.emit(GAME_EVENT.CAMERA_POSITION_CANCEL, {})
                const [panning] = emitter.emit(GAME_EVENT.TOUCH_START, params)
                return panning
            },
            onTouchMove: (params) => {
                emitter.emit(GAME_EVENT.TOUCH_MOVE, params)
            },
            onTouchEnd: (params) => {
                emitter.emit(GAME_EVENT.TOUCH_END, params)
            },
            onTouchClick: (params) => {
                emitter.emit(GAME_EVENT.TOUCH_CLICK, params)
            },
            onTouchDragged: (params) => {
                emitter.emit(GAME_EVENT.TOUCH_DRAGGED, params)
            },
        })
        hand = three.createHandHelper(
            replaceUrls({
                url_hand: `hand.png`,
                url_circle: `hand-circle.png`,
            })
        )

        emitter.emit(GAME_EVENT.THREE_READY, {})
    })

    emitter.on(GAME_EVENT.CAMERA_POSITION_TILES, ({ tiles, zoom, time }) => {
        if (tiles.length > 0) {
            const position = board.getMiddlePosition({ tiles })
            emitter.emit(GAME_EVENT.CAMERA_POSITION, { position, zoom, time })
        }
    })

    emitter.on(
        GAME_EVENT.CAMERA_POSITION,
        ({ position, zoom = false, time = 1000 }) => {
            if (three.scene) {
                camera_position = three.changeCameraPosition({
                    time,
                    col: position.col,
                    row: position.row,
                })
                if (zoom) {
                    camera_distance = three.changeCameraDistance({
                        distance: board_three.distance,
                        time,
                    })
                }
            }
        }
    )

    emitter.on(GAME_EVENT.CAMERA_POSITION_CANCEL, () => {
        if (camera_position !== undefined) {
            camera_position.stop()
            camera_position = undefined
        }
        if (camera_distance !== undefined) {
            camera_distance.stop()
            camera_position = undefined
        }
    })

    emitter.on(GAME_EVENT.TILE_OVER, ({ tile_id, player_id }) => {
        if (tiles_over[player_id] === undefined) {
            const color = board.getColorByPlayer({
                player_id,
                me: state.player_id,
            })
            tiles_over[player_id] = three.createTile(
                replaceUrls({
                    position_y: 0.001,
                    url: `tile-over-${color.player.toLowerCase()}.png`,
                })
            )
        }
        tiles_over[player_id].show()
        tiles_over[player_id].setPosition(board.tileToPosition(tile_id))
    })

    emitter.on(GAME_EVENT.HIDE_TILES_OVER, () => {
        Object.keys(tiles_over).forEach((player_id) => {
            tiles_over[player_id].hide()
        })
    })

    emitter.on(
        GAME_EVENT.RECRUITING_START,
        ({ player_id, unit_type, tile_id }) => {
            if (recruiting[player_id] !== undefined) {
                emitter.emit(GAME_EVENT.RECRUITING_CANCEL, { player_id })
            }

            const recruit = {}
            const { col, row } = board.tileToPosition(tile_id)
            const color = board
                .getColorByPlayer({ player_id, me: state.player_id })
                .player.toLowerCase()

            // Unit
            recruit.unit = createUnit({ unit_type, tile_id, color })
            recruit.unit.animateIddle({ loop: true })

            // Drag tile
            recruit.tile = three.createTile(
                replaceUrls({
                    url: `tile-drag-${color}.png`,
                    position_x: col,
                    position_z: row,
                    scale: 3,
                    opacity: 1,
                })
            )
            recruit.tile.highlight({ opacity_to: 0.5 })

            // Area
            if (player_id === state.player_id) {
                const { from, to } = board.state.teams[state.team_id].tiles
                recruit.area = three.createArea({
                    from: board.tileToPosition(from),
                    to: board.tileToPosition(to),
                    lineOpacity: 0.6,
                })
            }

            // // Move camera
            // const position = board.tileToPosition(tile_id)
            // emitter.emit(GAME_EVENT.CAMERA_POSITION, {
            //     position,
            //     time: 500,
            // })

            recruiting[player_id] = recruit
        }
    )

    emitter.on(GAME_EVENT.RECRUITING_MOVE, ({ player_id, tile_id }) => {
        const position = board.tileToPosition(tile_id)
        recruiting[player_id].unit.setPosition(position)
        recruiting[player_id].tile.setPosition(position)
    })

    emitter.on(GAME_EVENT.RECRUITING_CANCEL, ({ player_id }) => {
        if (recruiting[player_id] !== undefined) {
            recruiting[player_id].unit.remove()
            recruiting[player_id].tile.remove()
            if (recruiting[player_id].area !== undefined) {
                recruiting[player_id].area.remove()
            }
            delete recruiting[player_id]
        }
    })

    emitter.on(GAME_EVENT.RECRUITING_CANCEL_ALL, () => {
        Object.keys(recruiting).forEach((player_id) => {
            emitter.emit(GAME_EVENT.RECRUITING_CANCEL, { player_id })
        })
    })

    emitter.on(GAME_EVENT.UPDATE_UNITS_ACTIONS, () => {
        const { player_id } = state
        const { can_action } = board.getTurnStatusByPlayer({ player_id })
        board
            .getUnitAvailabilityByPlayer({ player_id })
            .forEach(({ unit_id, available, can_walk, can_attack }) => {
                if (units.hasOwnProperty(unit_id)) {
                    units[unit_id].unhighlightTile()
                    if (can_action && available) {
                        units[unit_id].showActionsTiles({
                            can_walk,
                            can_attack,
                        })
                        if (
                            state.unit_selected === null ||
                            state.unit_selected === unit_id
                        ) {
                            units[unit_id].highlightTile()
                        }
                    } else {
                        units[unit_id].hideActionsTiles()
                    }
                }
            })
    })

    emitter.on(GAME_EVENT.UNIT_SELECT, ({ unit_id }) => {
        units[unit_id].setColorActionsTiles(TILE_COLORS.GREEN)
        units[unit_id].animateIddle({ loop: true })
    })

    emitter.on(GAME_EVENT.UNIT_UNSELECTED, ({ unit_id }) => {
        units[unit_id].setColorActionsTiles(TILE_COLORS.WHITE)
        units[unit_id].animateIddle({ loop: false })
    })

    emitter.on(GAME_EVENT.UNIT_ATTACK_ENDED, ({ unit_id }) => {
        units[unit_id].animateIddle({ loop: false })
    })

    emitter.on(GAME_EVENT.HAND_SHOW, ({ tile_id, color }) => {
        const position = board.tileToPosition(tile_id)
        hand.show()
        hand.setPosition(position)
        hand.animateClick({ color })
    })

    emitter.on(GAME_EVENT.HAND_HIDE, () => {
        hand.hide()
        hand.stopAnimateClick({})
    })

    emitter.on(GAME_EVENT.TILES_WALK_SHOW, ({ tiles }) => {
        tiles.forEach((tile_id) => {
            if (tiles_walk[tile_id] === undefined) {
                const { col, row } = board.tileToPosition(tile_id)
                const tile = three.createTile({
                    url: replaceUrls({
                        url: 'tile-walk.png',
                    }).url,
                    opacity: 0.5,
                    position_x: col,
                    position_z: row,
                })
                tiles_walk[tile_id] = tile
            }

            tiles_walk[tile_id].show()
        })
    })

    emitter.on(GAME_EVENT.TILES_WALK_HIDE_ALL, () => {
        Object.keys(tiles_walk).forEach((tile_id) => {
            tiles_walk[tile_id].hide()
        })
    })

    emitter.on(GAME_EVENT.TILES_HIGHLIGHT_SHOW, ({ tiles }) => {
        tiles.forEach(({ tile_id, color = TILE_COLORS.WHITE }) => {
            if (tiles_highlight[tile_id] === undefined) {
                const { col, row } = board.tileToPosition(tile_id)
                const tile = three.createTile({
                    cache: false,
                    url: replaceUrls({
                        url: 'tile-attack.png',
                    }).url,
                    opacity: 1,
                    position_x: col,
                    position_z: row,
                })
                tiles_highlight[tile_id] = tile
            }

            tiles_highlight[tile_id].show()
            tiles_highlight[tile_id].highlight({
                opacity_to: 0.5,
                scale_to: 0.8,
            })
            tiles_highlight[tile_id].setColor(color)
        })
    })

    emitter.on(GAME_EVENT.TILES_HIGHLIGHT_HIDE, ({ tiles }) => {
        tiles.forEach((tile_id) => {
            tiles_highlight[tile_id].hide()
            tiles_highlight[tile_id].unhighlight()
        })
    })

    emitter.on(GAME_EVENT.TILES_HIGHLIGHT_HIDE_ALL, () => {
        Object.keys(tiles_highlight).forEach((tile_id) => {
            tiles_highlight[tile_id].hide()
            tiles_highlight[tile_id].unhighlight()
        })
    })

    emitter.on(GAME_EVENT.TILES_RANGE_CREATE, ({ unit_id }) => {
        const { tile_id, range } = board.state.units[unit_id]
        if (!tiles_range.hasOwnProperty(unit_id)) {
            const tiles = board
                .getTilesByRange({ tile_id: '0.0', range })
                .map(board.tileToPosition)

            tiles_range[unit_id] = three.createRangeTiles({
                tiles,
                opacity: 0.8,
                position_y: 0.01,
                url: replaceUrls({
                    url: 'tile-range.png',
                }).url,
            })
        }
        emitter.emit(GAME_EVENT.TILES_RANGE_MOVE, { unit_id, tile_id })
    })

    emitter.on(GAME_EVENT.TILES_RANGE_VISIBLE, ({ unit_id, visible }) => {
        visible ? tiles_range[unit_id].show() : tiles_range[unit_id].hide()
    })

    emitter.on(GAME_EVENT.TILES_RANGE_MOVE, ({ unit_id, tile_id }) => {
        tiles_range[unit_id].setPosition(board.tileToPosition(tile_id))
    })

    emitter.on(GAME_EVENT.TILES_RANGE_REMOVE, ({ unit_id }) => {
        tiles_range[unit_id].remove()
        delete tiles_range[unit_id]
    })

    emitter.on(
        GAME_EVENT.PATH_WALK_CREATE,
        ({ player_id, tile_id_from, tile_id_to }) => {
            if (path_walk[player_id] !== undefined) {
                emitter.emit(GAME_EVENT.PATH_WALK_REMOVE, { player_id })
            }

            const path = board.getWalkablePath({ tile_id_from, tile_id_to })
            path.unshift(tile_id_from)
            path_walk[player_id] = three.createPath({
                path: path.map(board.tileToPosition),
                url: replaceUrls({
                    url: 'line-walk.png',
                }).url,
            })
            path_walk[player_id].animate({})

            const color = board.getColorByPlayer({
                player_id,
                me: state.player_id,
            })
            path_walk[player_id].setColor({
                color:
                    player_id === state.player_id
                        ? 0xffffff
                        : TILE_COLORS[color.player],
            })
            path_walk[player_id].setOpacity({
                opacity: player_id === state.player_id ? 1 : 0.25,
            })
        }
    )

    emitter.on(GAME_EVENT.PATH_WALK_REMOVE, ({ player_id }) => {
        if (path_walk[player_id] !== undefined) {
            path_walk[player_id].remove()
            delete path_walk[player_id]
        }
    })

    emitter.on(GAME_EVENT.PATH_WALK_REMOVE_ALL, () => {
        Object.keys(path_walk).forEach((player_id) => {
            emitter.emit(GAME_EVENT.PATH_WALK_REMOVE, { player_id })
        })
    })

    emitter.on(
        GAME_EVENT.PATH_ATTACK_CREATE,
        ({ player_id, tile_id_from, tile_id_to }) => {
            const path = [tile_id_from, tile_id_to]
            path_attack[player_id] = three.createPath({
                path: path.map(board.tileToPosition),
                // repeat_factor: 2.5,
                line_height: 0.25,
                url: replaceUrls({
                    url: 'line-attack.png',
                }).url,
            })
            path_attack[player_id].animate({})

            if (player_id !== state.player_id) {
                path_attack[player_id].setOpacity({
                    opacity: 0.25,
                })
            }
        }
    )

    emitter.on(GAME_EVENT.PATH_ATTACK_REMOVE, ({ player_id }) => {
        if (path_attack[player_id] !== undefined) {
            path_attack[player_id].remove()
            delete path_attack[player_id]
        }
    })

    emitter.on(GAME_EVENT.PATH_ATTACK_REMOVE_ALL, () => {
        Object.keys(path_attack).forEach((player_id) => {
            emitter.emit(GAME_EVENT.PATH_ATTACK_REMOVE, { player_id })
        })
    })

    emitter.on(GAME_EVENT.HIDE_ALL, () => {
        Object.keys(units).forEach((unit_id) => {
            units[unit_id].hideActionsTiles()
        })
    })

    // GAME_ACTION
    // GAME_ACTION
    // GAME_ACTION
    // GAME_ACTION
    // GAME_ACTION

    emitter.on(GAME_ACTION.SET_BOARD, ({ params }) => {
        board_three = BOARDS[params.name]
        const board = replaceUrls(board_three)
        three.createBoard(board)
    })

    emitter.on(GAME_ACTION.FLAG_ADD, ({ params }) => {
        const { flag_id } = params
        const { team_id } = board.state.flags[flag_id]
        flags[flag_id] = three.createFlag(replaceUrls(ENTITIES.flag()))
        flags[flag_id].setPosition(board.tileToPosition(params.tile_id))
        flags[flag_id].setTeam({ color: COLOR_NAME.WHITE.toLowerCase() })
    })

    queues.on(GAME_ACTION.FLAG_CAPTURED, ({ params }) => {
        const { flag_id, team_id } = params
        const color =
            team_id === state.team_id ? COLOR_NAME.BLUE : COLOR_NAME.RED
        flags[flag_id].setTeam({ color: color.toLowerCase() })
    })

    emitter.on(GAME_ACTION.UNIT_ADD, ({ params }) => {
        const { unit_id, unit } = params
        const { tile_id, unit_type } = unit
        const [player_id] = unit.players
        const color = board
            .getColorByPlayer({ player_id, me: state.player_id })
            .player.toLowerCase()

        units[unit_id] = createUnit({
            unit_type,
            tile_id,
            color,
        })
        units[unit_id].animateIddle({ loop: false })
    })

    queues.on(GAME_ACTION.UNIT_WALK, async ({ params }) => {
        const { path, unit_id } = params
        if (isWindowActive()) {
            AUDIO.WALK(true)
            await units[unit_id].animateWalk({
                path: path.slice(1).map(board.tileToPosition),
            })
            AUDIO.WALK(false)
        }
        emitter.emit(GAME_EVENT.UNIT_WALK_ENDED, { unit_id })
    })

    queues.on(GAME_ACTION.UNIT_CHANGE_TILE, ({ params, unpatch }) => {
        const { unit_id, tile_id } = params
        const from = unpatch.units[unit_id].tile_id

        const flag_from = board.getFlagByTile({ tile_id: from })
        const flag_to = board.getFlagByTile({ tile_id })

        if (flag_from !== undefined) {
            flags[flag_from].unhighlight()
        }

        if (flag_to !== undefined) {
            flags[flag_to].highlight()
        }

        units[unit_id].setPosition(board.tileToPosition(tile_id))
    })

    queues.on(GAME_ACTION.UNIT_ATTACK, async ({ params }, queue) => {
        const { unit_id, unit_id_target, tile_id, tile_id_target, damage } =
            params

        units[unit_id_target].lookAt(board.tileToPosition(tile_id))
        if (isWindowActive()) {
            const distance = board.getDistanceFromTiles({
                tile_id1: tile_id,
                tile_id2: tile_id_target,
            })
            await units[unit_id].animateAttack({
                to: board.tileToPosition(tile_id_target),
            })
            distance > 1 ? AUDIO.ATTACK_RANGE() : AUDIO.ATTACK_MELEE()
        }
        emitter.emit(GAME_EVENT.UNIT_ATTACK_ENDED, { unit_id })
    })

    queues.on(GAME_ACTION.UNIT_HIT, ({ params }) => {
        const { unit_id, damage } = params
        units[unit_id].animateHit({ damage })
    })

    queues.on(GAME_ACTION.UNIT_LIFE, ({ params }) => {
        const { unit_id, life } = params
        units[unit_id].setLife(life)
    })

    queues.on(GAME_ACTION.UNIT_DEATH, async ({ params }) => {
        const { unit_id } = params
        if (isWindowActive()) {
            AUDIO.DEATH()
            await units[unit_id].animateDeath({})
        }
    })

    queues.on(GAME_ACTION.UNIT_REMOVE, ({ params, unpatch }) => {
        const { unit_id } = params
        units[unit_id].remove()
        const { tile_id } = unpatch.units[unit_id]
        const flag_id = board.getFlagByTile({ tile_id })
        if (flag_id !== undefined) {
            flags[flag_id].unhighlight()
        }
    })

    queues.on(GAME_ACTION.UNIT_ICON_ADD, ({ params }) => {
        const { unit_id, uid, icon } = params
        const url = `icons/${icon}.png`
        units[unit_id].iconAdd({ id: uid, url: replaceUrls({ url }).url })
    })

    queues.on(GAME_ACTION.UNIT_ICON_REMOVE, ({ params }) => {
        const { unit_id, uid } = params
        units[unit_id].iconRemove({ id: uid })
    })

    queues.on(GAME_ACTION.WINNER, async ({ params }) => {
        // console.log(params)
        await wait(500)
        AUDIO.MUSIC(false)
        params.team_id === board.getTeamByPlayer({ player_id: state.player_id })
            ? AUDIO.VICTORY()
            : AUDIO.DEFEAT()

        emitter.emit(GAME_EVENT.WINNED, {})
    })

    emitter.on(GAME_ACTION.CARD_USE, ({ params }) => {
        if (params.subparams.hasOwnProperty('unit_id')) {
            units[params.subparams.unit_id].highlight({
                repeat: 6,
                yoyo: false,
            })
        }
    })

    function createUnit({ unit_type, tile_id, color }) {
        const unit_data = UNITS[unit_type]({ tile_id })
        const position = board.tileToPosition(tile_id)
        const unit = three.createUnit(
            replaceUrls(ENTITIES[unit_type.toLowerCase()](color))
        )
        unit.setLife(unit_data.life, unit_data.maxlife)
        unit.setPosition(position)
        // unit.stop()
        unit.lookAt({
            col: position.col,
            row: Math.round(board.state.rows / 2),
        })

        return unit
    }
}

function replaceUrls(data) {
    return mergewith({}, data, (target, asset, prop) => {
        if (prop.indexOf('url') === 0 && typeof asset === 'string') {
            return THREE_ASSETS[asset]
        }
    })
}
